<app-breadcrumb [title]="'Agregar campaña'" [items]="['Campañas', 'Agregar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group">
                            <label for="">Nombre de campaña</label>
                            <input class="form-control" type="text" placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="">Audiencia</label>
                            <div class="buttons">
                                <button class="btn btn-primary d-inline">Filtrar audiencia</button>
                                <button class="btn btn-secondary d-inline">Importar audiencia</button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Fecha de envío</label>
                            <input type="datetime-local" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="">Plantilla</label>
                            <div class="buttons">
                                <button class="btn btn-primary">Seleccionar plantilla</button>
                            </div>
                        </div>
                        <div class="buttons">
                            <button class="btn btn-secondary">Agregar campaña</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>