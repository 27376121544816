import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})



export class AudienceService {


    constructor(private http: HttpClient) { }



    async get_audience_by_name(name) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const data = {
            'jwt': JSON.parse(localStorage.getItem("user")).jwt,
            'name': name
        }
        const response = await lastValueFrom(this.http.post(`${environment.node_api}api/audience/get_by_name`, data, { headers }));

        return response
    }

    async generateAudience(audience) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('audience', JSON.stringify(audience))


        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_audience`, data));

        return response
    }

    async generate_and_uploadAudienceFromExcel(audience, excel) {
        const data = new FormData()
        data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
        data.append('excel', excel)
        data.append('audiencia', audience)

        const response = await lastValueFrom(this.http.post(`${environment.url_api}Audience/generate_and_uploadAudienceFromExcel`, data));
        console.log(response)
    }

}
