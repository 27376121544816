<app-breadcrumb [title]="'Generar audiencia desde un archivo excel'"
    [items]="['Campañas', 'Audiencias','Generar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5><u>Audiencia:</u></h5>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nombre de la audiencia</label>
                            <div class="col-sm-9">
                                <input class="form-control" [(ngModel)]="audiencia.name" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Tipo de audiencia</label>
                            <div class="col-sm-9">
                                <ng-select [items]="audienciaType" class="js-example-basic-multiple" placeholder=""
                                    [(ngModel)]="audiencia.type">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <br>
                <div class="row dropzone-custom">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Suba aquí su archivo de excel</h5>
                            </div>
                            <div class="card-body">
                                <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="icon-cloud-up"></i>
                                            <h6>Arrastre aquí su archivo</h6>
                                            <!-- <span>(This is just a demo dropzone. Selected files are
                                                        <strong>not</strong> actually uploaded.)</span> -->
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                        (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                        </div>
                    </div>

                </div>

                <button (click)="generate_audience()" class="btn btn-primary">¡Generar audiencia!</button>
            </div>
        </div>
    </div>
</div>
<br>