<app-breadcrumb [title]="'Historial de encuestas'" [items]="['Campañas', 'Configuración','NPS']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, podrás crear encuestas</small>
                    </div>
                    <div class="media-body text-end">
                        <a [routerLink]="'add'" class="btn btn-primary me-2">Crear encuesta +</a>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Nombre de la encuesta</th>
                                <th class="text-center" scope="col">Fecha de creación</th>
                                <th class="text-center" scope="col">Creado por</th>
                                <th class="text-center" scope="col">Ver</th>
                                <th class="text-center" scope="col">Registros</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of encuestas">
                                <td>{{e.name}}</td>
                                <td class="text-center">{{e.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-secondary">Luis Miguel</span>
                                </td>
                                <td class="text-center">
                                    <a target="_blank" href="https://autos.honda.com.pe/encuesta/{{e.id}}/prueba"> <i
                                            class="fa fa-arrow-right"></i></a>
                                </td>
                                <td class="text-center">
                                    <a target="_blank" style="color:green; font-weight: bold;"
                                        href="https://leads.pulpa.digital/Encuesta_Reporte/generar_reporte/{{e.id}}"> <i
                                            class="fa fa-file-excel-o"></i></a>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>