import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-campaigns',
  templateUrl: './loyalty-campaigns.component.html',
  styleUrls: ['./loyalty-campaigns.component.scss']
})
export class LoyaltyCampaignsComponent implements OnInit {

  protected campaigns = [
    {
      id: 1,
      name: 'HONDA 4W - HONDA REMARKETING BBDD 1 14.09.23',
      motivo: 'Motivo 1',
      fecha: '2023-01-01 12:15:00',
      created_by: 'Alessandra Lay',
      cantidad_audiencia : 10456,
      status: 'Enviado',
      status_class : 'badge-success'
    },
    {
      id: 2,
      name: 'HONDA 4W - HONDA RECOMPRA 14.09.23',
      motivo: 'Motivo 1',
      fecha: '2023-12-01 12:15:00',
      created_by: 'Fiorella Velita',
      cantidad_audiencia : 9458,
      status: 'Programado',
      status_class : 'badge-secondary'
    },
    {
      id: 3,
      name: 'HONDA 4W - HONDA REFERIDOS 14.09.23',
      motivo: 'Motivo 1',
      fecha: '2023-01-01 12:15:00',
      created_by: 'Alessandra Lay',
      cantidad_audiencia : 16159,
      status: 'Enviado',
      status_class : 'badge-success'
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
