import { Component, OnInit } from '@angular/core';
import { AudienceService } from '../shared/services/audience.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-audience-add-from-excel',
  templateUrl: './loyalty-campaigns-audience-add-from-excel.component.html',
  styleUrls: ['./loyalty-campaigns-audience-add-from-excel.component.scss']
})
export class LoyaltyCampaignsAudienceAddFromExcelComponent implements OnInit {

  files: File[] = [];
  audiencia
  audienciaType

  constructor(
    private audienceService: AudienceService
  ) {
    this.audiencia = {
      name: 'AUDIENCIA DE PRUEBA',
      type: 'Remarketing',
      filtros: {}
    }
    this.audienciaType = [
      'Remarketing', 'Recompra', 'Encuesta'
    ]
  }

  ngOnInit(): void {
    Swal.fire('Temporalmente deshabilitado', '', 'warning')
  }


  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) {
      this.replaceFile();
    }
  }

  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  generate_audience() {
    Swal.fire('Temporalmente deshabilitado', '', 'warning')
    // this.audienceService.generate_and_uploadAudienceFromExcel(
    //   this.audiencia, this.files[0]
    // )
  }



}
