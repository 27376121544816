import { AfterViewInit, Component, OnInit } from '@angular/core';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LeadsService } from '../shared/services/leads.service';
import { AudienceService } from '../shared/services/audience.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-loyalty-campaigns-audience-add-from-crm',
  templateUrl: './loyalty-campaigns-audience-add-from-crm.component.html',
  styleUrls: ['./loyalty-campaigns-audience-add-from-crm.component.scss']
})
export class LoyaltyCampaignsAudienceAddFromCrmComponent implements AfterViewInit, OnInit {

  audiencia
  audienciaType

  filtros
  default_values
  user_type_id
  buttonFiltrarTexto
  isCollapsed

  leads
  total_filtered


  dtElement: DataTableDirective;
  dtTrigger: Subject<void> = new Subject();


  constructor(

    private leadService: LeadsService,
    private audienceService: AudienceService
  ) {
    this.filtros = {}
    this.leads = []
    this.default_values = {}
    this.user_type_id = JSON.parse(localStorage.user).user_type_id

    this.buttonFiltrarTexto = `Filtrar`
    this.total_filtered = 0
    this.audiencia = {
      name: 'AUDIENCIA DE PRUEBA',
      type: '',
      filtros: {}
    }

    this.audienciaType = [
      'Buen score financiero', 'Zona Norte', 'Zona Sur', 'Zurdos'
    ]
  }

  async ngAfterViewInit() {
    const res = <any>await this.leadService.lead_service_init_general()
    if (res.success) {
      this.default_values = res.payload
      console.log(this.default_values)
    }
  }

  ngOnInit() {
    Swal.fire('Funcion temporalmente deshabilitada', '', 'warning')
  }

  rerender(): void {

    this.buttonFiltrarTexto = `Cargando...`
    console.log('clickeo filtros : ', this.filtros)
    this.make_filter()
  }

  async make_filter() {
    this.filtros.start = 1
    this.filtros.length = 1
    Swal.showLoading()
    const res = <any>await this.leadService.make_filter(this.filtros)
    Swal.close()
    if (res.success) {
      this.leads = res.data
      this.total_filtered = 0
      this.audiencia.filtros = this.filtros

      console.log(res.detallado_leads)
      res.detallado_leads.forEach(element => {
        this.total_filtered += parseInt(element.cantidad)
      });




    }

    this.buttonFiltrarTexto = `Filtrar`
    console.log(res)
  }

  async generar_audiencia() {
    Swal.fire('Funcion temporalmente deshabilitada', '', 'warning')
    return 0
    const res = await this.validation()
  }

  async validation() {
    Swal.showLoading()
    const res = <any>await this.audienceService.get_audience_by_name(this.audiencia.name)
    if (res.success) {

      Swal.fire({
        title: "¿Estás seguro?",
        html: `Vas a crear una audiencia de <b>${this.total_filtered}</b> persona(s)`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.close()
          Swal.showLoading()
          this.generateAudience()
        }
      });


    } else {
      Swal.fire({
        title: "Error",
        text: res.message,
        icon: "error"
      });
    }
  }

  async generateAudience() {
    Swal.showLoading()
    const res = <any>await this.audienceService.generateAudience(this.audiencia)
    if (res.success) {
      Swal.fire({
        title: res.message,
        text: ``,
        icon: "success"
      });
    } else {
      Swal.fire({
        title: res.message,
        text: ``,
        icon: "error"
      });
    }

  }
}
