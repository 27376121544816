import { Component, OnInit } from '@angular/core';
import { EncuestaService } from '../shared/services/encuesta.service';

@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent implements OnInit {

  encuestas = []

  constructor(
    private encuestaService: EncuestaService,
  ) { }

  async ngOnInit() {
    const response = await this.encuestaService.get_encuestas();
    this.encuestas = response.encuestas
  }

}
