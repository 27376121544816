<app-breadcrumb [title]="'Agregar encuesta'" [items]="['Campañas', 'Configuración', 'NPS']"></app-breadcrumb>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, podrás crear encuestas</small>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col-12 col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ingrese el nombre de la encuesta
                            </label>
                            <div class="col-sm-9">
                                <input class="form-control con_help digits" type="text" [(ngModel)]="encuesta.name">
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Este será el nombre que se visualizará como título en la encuesta cuando el usuario ingrese">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Días que estará disponible la encuesta</label>
                            <div class="col-sm-9">
                                <input min="1" class="form-control digits con_help" type="number"
                                    [(ngModel)]="encuesta.dias_disponible">
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Los días se contarán a partir de que la campaña ha sido enviada, una vez pasado los {{encuesta.dias_disponible}} días, el usuario no podrá ingresar a la encuesta">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Seleccione el tipo de encuesta</label>
                            <div class="col-sm-9">
                                <ng-select [items]="tipo_encuesta" class="js-example-basic-multiple con_help"
                                    bindLabel="name" bindValue="id" [(ngModel)]="encuesta.type"
                                    (ngModelChange)="onTipoEncuestaChange()">
                                </ng-select>
                                <div class="help" data-container="body" placement="left"
                                    ngbTooltip="Los tipos de encuesta involuntarias son encuestas que por lo general serán impactadas por campañas de marketing, el restante ...">
                                    <i data-feather="info"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="encuesta.type == 1">
                            <label class="col-3 col-form-label">Seleccione el subtipo de encuesta</label>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-9">
                                        <ng-select [items]="subtypesOptions" class="js-example-basic-multiple"
                                            bindLabel="name" bindValue="id" [(ngModel)]="encuesta.sub_type">
                                        </ng-select>
                                    </div>
                                    <div class="col-3">
                                        <button (click)="agregarSubtipoEncuesta()" class="btn btn-danger"> + </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>


                    <div class="col-12 col-md-12" *ngIf="encuesta.type > 1">
                        <div class="text-first">
                            <h5>Preguntas personales: </h5>
                        </div>
                        <div class="media-body text-end">
                            <div class="row">
                                <div class="col-12">
                                    <small>Al elegir este tipo de encuesta, por defecto se
                                        cargará las preguntas genéricas, puedes agregar otro tipo de preguntas
                                        también</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngFor="let pregunta of preguntas_genericas; let i = index">
                            <div class="form-group row">
                                <div class="row">
                                    <label class="col-sm-3 col-form-label">Dato personal Nº {{i+1}}</label>

                                    <div class="col-sm-9" *ngIf="[0,1,2,3].includes(i)">
                                        <input disabled class="form-control digits" type="text"
                                            [(ngModel)]="preguntas_genericas[i].name">
                                    </div>
                                    <div class="col-sm-9" *ngIf="i >= 4">
                                        <div class="row">
                                            <div class="col-8">
                                                <input disabled class="form-control digits" type="text"
                                                    [(ngModel)]="preguntas_genericas[i].name">
                                            </div>
                                            <div class="col-2">
                                                <button (click)="editarDatoPersonal(i)" class="btn btn-secondary">
                                                    <i alt="Editar" class="icon-pencil-alt2"></i>
                                                </button>
                                            </div>
                                            <div class="col-2">
                                                <button (click)="eliminarDatoPersonal(i)" class="btn btn-primary">
                                                    <i alt="Eliminar" class="icon-trash"></i>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-primary" (click)="agregarDatoPersonal()">Agregar dato personal </button>
                        <hr>
                    </div>

                    <div class="col-12 col-md-12" tooltipContainer>
                        <div class="form-group row" *ngFor="let pregunta of preguntas; let i = index">
                            <div class="form-group row">
                                <h6>Pregunta Nº {{i+1}}</h6>
                                <div class="row">
                                    <label class="col-sm-3 col-form-label">Ingrese la pregunta</label>
                                    <div class="col-sm-7">
                                        <input class="form-control digits" type="text" [(ngModel)]="preguntas[i].texto">
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="btn btn-danger" (click)="eliminarPregunta(i)">Eliminar</button>
                                    </div>
                                </div>
                                <div class="row m-t-20">
                                    <label class="col-sm-3 col-form-label">Seleccione tipo de pregunta</label>
                                    <div class="col-sm-9">
                                        <ng-select [items]="tipo_preguntas" class="js-example-basic-multiple con_help"
                                            placeholder="Seleccione" bindLabel="name" bindValue="id"
                                            [(ngModel)]="preguntas[i].tipo">
                                        </ng-select>

                                        <div *ngIf="preguntas[i].tipo - 1 >= 0" class="help_button"
                                            (click)="mostrarHelp(preguntas[i].tipo - 1)">
                                            <button class="btn btn-help">?</button>
                                        </div>


                                    </div>
                                </div>
                                <div class="row m-t-20" *ngIf="preguntas[i].tipo === 2">
                                    <label class="col-sm-3">
                                        ¿Deseas agregar subpreguntas por rango?
                                    </label>
                                    <div class="col-sm-9">
                                        <label class="switch">
                                            <input [(ngModel)]="preguntas[i].subPregunta[0].has_subpregunta"
                                                type="checkbox"><span class="switch-state"></span>
                                        </label>
                                    </div>
                                    <div class="col-12" *ngIf="preguntas[i].subPregunta[0].has_subpregunta">
                                        <div class="row m-t-20"
                                            *ngFor="let rango of preguntas[i].subPregunta[0].rangos; let j = index">
                                            <label class="col-sm-3 col-form-label">Rango {{rango.name}}</label>
                                            <div class="col-sm-9">
                                                <input class="form-control digits" type="text"
                                                    [(ngModel)]="preguntas[i].subPregunta[0].rangos[j].pregunta"
                                                    placeholder="Ingrese pregunta">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Input y botones para opciones si el tipo es 4 -->
                                <div class="row m-t-20" *ngIf="preguntas[i].tipo === 4">
                                    <label class="col-sm-3 col-form-label">Opciones</label>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-7">
                                                <div class="row">
                                                    <div class="col-sm-7">
                                                        <input class="form-control digits" type="text"
                                                            [(ngModel)]="preguntas[i].nuevaOpcion"
                                                            placeholder="Ingrese opción">
                                                    </div>
                                                    <div class="col-sm-5">
                                                        <button class="btn btn-success"
                                                            (click)="agregarOpcion(i)">+</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-5">
                                                <ng-select [items]="tipo_de_opciones" class="js-example-basic-multiple"
                                                    placeholder="Seleccione" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="preguntas[i].subtipo">
                                                </ng-select>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-sm-12 m-t-10">
                                        <ul class="opciones">
                                            <li *ngFor="let opcion of preguntas[i].opciones; let j = index">
                                                <button class="btn btn-danger btn-sm"
                                                    (click)="eliminarOpcion(i, j)">-</button>
                                                <span>{{ opcion }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-12 botones">
                        <button class="btn btn-primary" (click)="agregarPregunta()">Agregar
                            Pregunta +</button>
                    </div>
                    <hr>
                    <div class="col-12 botones m-t-20">
                        <button class="btn btn-secondary" (click)="validarEncuesta()">Grabar encuesta</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>