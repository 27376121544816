<app-breadcrumb [title]="'Audiencias'" [items]="['Campañas', 'Audiencias']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 item" [routerLink]="'add-from-crm'">
                            <div class="card bg-primary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Crear desde base de clientes</h5>
                                            <p>Genera una audiencia desde base de leads</p>
                                        </div><i data-feather="book-open"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 item" [routerLink]="'add-from-excel'">
                            <div class="card bg-success">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Importar desde excel</h5>
                                            <p>Genera una audiencia desde un archivo Excel</p>
                                        </div><i data-feather="file-text"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-6 item" [routerLink]="''">
                            <div class="card bg-secondary">
                                <div class="card-body">
                                    <div class="media faq-widgets">
                                        <div class="media-body">
                                            <h5>Crear base desde etiquetas</h5>
                                        </div><i data-feather="tag"></i>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>