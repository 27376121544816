<app-breadcrumb [title]="'Campañas'" [items]="['Campañas']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>Aca se muestra todo el listado de campañas generados por el sistema.<br>
                            <b>La data mostrada no es real</b>

                        </small>
                    </div>
                    <div class="media-body text-end">
                        <!-- <a [routerLink]="'add'" class="btn btn-primary me-2">Crear campaña +</a> -->
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Nombre de la campaña</th>
                                <!-- <th class="text-center" scope="col">Motivo</th> -->
                                <th class="text-center" scope="col">Fecha</th>
                                <th class="text-center" scope="col">Creado por</th>
                                <th class="text-center" scope="col">Audiencia</th>
                                <th class="text-center" scope="col">Estado</th>
                                <th class="text-center" scope="col">Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of campaigns">
                                <td>{{c.name}}</td>
                                <!-- <td class="text-center">{{c.motivo}}</td> -->
                                <td class="text-center">{{c.fecha}}</td>
                                <td class="text-center">{{c.created_by}}</td>
                                <td class="text-center">{{c.cantidad_audiencia}}</td>
                                <td class="text-center">
                                    <span class="badge" [ngClass]="c.status_class">{{c.status}}</span>
                                </td>
                                <td class="text-center">
                                    <a [routerLink]="'detail/'+c.id"><i class="fa fa-arrow-right"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    </div>
</div>